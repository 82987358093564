import { BABYLON } from "vue-babylonjs";
import { createScene } from "../../common/sceneHelper";
import { VIEWER_TYPES } from "../types";
const state = {
    model: "./assets/4323_Pivot_Beinpresse45_6.07.21.gltf",
    scene: null,
    materials: null,
    materials2: null,
    finished: false,
    scene2: null,
};
const getters = {
    [VIEWER_TYPES.GET_MODEL]: () => state.model,
    [VIEWER_TYPES.GET_SCENE]: (state) => state.scene,
    [VIEWER_TYPES.GET_SCENE_2]: (state) => state.scene2,
    [VIEWER_TYPES.GET_MATERIALS]: (state) => state.materials,
    [VIEWER_TYPES.GET_MATERIALS_2]: (state) => state.materials2,
    [VIEWER_TYPES.GET_FINISHED]: (state) => state.finished,
};
const actions = {
    async [VIEWER_TYPES.LOAD_SCENE]() {
        state.finished = false;
        const canvas = document.getElementById("scene");
        const engine = new BABYLON.Engine(canvas, true);

        let scene = await createScene(engine, canvas);
        engine.runRenderLoop(() => {
            state.scene.render();
        });
        state.scene = scene
        setTimeout(() => { state.materials = state.scene.materials }, 1000)
        state.finished = true;
        return scene
    },
};
const mutations = {
    [VIEWER_TYPES.SET_MODEL]: (state, value) => (state.model = value),
    [VIEWER_TYPES.SET_SCENE]: (state, value) => (state.scene = value),
    [VIEWER_TYPES.SET_MATERIALS]: (state, value) => (state.materials = value),
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
