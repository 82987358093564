import { HELPERS_TYPES } from "../types";
import router from "../../router";
import ApiService from "../../common/apiService";

const state = {
    isSidebarActive: true,
    getFilterRouteName: null,
    loadingIds: [],
    errorIds: {},
    selectedLanguage: "de"
};
const getters = {
    [HELPERS_TYPES.GET_IS_SIDEBAR_ACTIVE]: (state) => state.isSidebarActive,
    [HELPERS_TYPES.GET_FILTER_ACTIVE_ROUTE]: (state) =>
        state.getFilterRouteName,
    [HELPERS_TYPES.GET_LOADING_IDS]: (state) => state.loadingIds,
    [HELPERS_TYPES.GET_ERROR_IDS]: (state) => state.errorIds,
    [HELPERS_TYPES.GET_SELECTED_LANGUAGE]: (state) => state.selectedLanguage,
};
const actions = {
    
    async [HELPERS_TYPES.SAVE_USER_IMAGES](_1,images) {
        const response = await ApiService.post(`/saveImages`,images);
        return response.data
    },
    async [HELPERS_TYPES.DISPLAY_IMAGE](_1,imageURL) {
        const response = await ApiService.get(`image?imageURL=`,imageURL);
        return response.data
    },
    async [HELPERS_TYPES.DELETE_IMAGES](_1,directory) {
        await ApiService.post(`deleteImages`,directory);
    },
    async [HELPERS_TYPES.SEND_BOOKMARKS](_1,form) {
        const response = await ApiService.post(`inquiry`,form);
        return response
    },

};
const mutations = {
    [HELPERS_TYPES.SET_IS_SIDEBAR_ACTIVE]: (state, value) =>
        (state.isSidebarActive = value),
    [HELPERS_TYPES.SET_FILTER_ACTIVE_ROUTE]: (state) => {
        if (state.getFilterRouteName) {
            state.getFilterRouteName = null;
        } else {
            state.getFilterRouteName = router.currentRoute.value.name;
        }
    },
    [HELPERS_TYPES.SET_LOADING_IDS]: (state, array) =>
        (state.loadingIds = array),
    [HELPERS_TYPES.SET_LANGUAGE]: (state, string) =>
        (state.selectedLanguage = string),
    [HELPERS_TYPES.SET_LOADING_ID]: (state, string) =>
        state.loadingIds.push(string),
    [HELPERS_TYPES.REMOVE_LOADING_ID]: (state, string) =>
    (state.loadingIds = state.loadingIds.filter(
        (loadingId) => loadingId != string
    )),
    [HELPERS_TYPES.SET_ERROR_IDS]: (state, object) => (state.errorIds = object),
    [HELPERS_TYPES.SET_ERROR_ID_OBJECT]: (state, object) =>
        (state.errorIds = Object.assign(state.errorIds, object)),
    [HELPERS_TYPES.REMOVE_ERROR_ID]: (state, string) =>
        (state.errorIds[string] = null),
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
