<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="login" transform="translate(24) rotate(90)">
      <rect
        id="Rechteck_1807"
        data-name="Rechteck 1807"
        width="24"
        height="24"
        transform="translate(24) rotate(90)"
        fill="none"
      />
      <path
        id="outline2"
        d="M938.859,103.47l-4-4a1,1,0,0,0-1.414,1.414l2.293,2.293h-9.722a1,1,0,0,0,0,2h9.722l-2.293,2.293a1,1,0,1,0,1.414,1.414l4-4A1,1,0,0,0,938.859,103.47Z"
        transform="translate(-923.016 -92.178)"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="outline1"
        d="M944.016,114.177h-8a1,1,0,0,1,0-2h7v-16h-7a1,1,0,0,1,0-2h8a1,1,0,0,1,1,1v18A1,1,0,0,1,944.016,114.177Z"
        transform="translate(-923.016 -92.178)"
        :class="fillClass + ' transition-fill'"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DropIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>
