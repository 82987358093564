<template>
    <svg
        id="Edit"
        xmlns="http://www.w3.org/2000/svg"
        width="20.329"
        height="20.089"
        viewBox="0 0 20.329 20.089"
    >
        <path
            id="Pfad_419"
            data-name="Pfad 419"
            d="M15.3,20.1H1.3A1.324,1.324,0,0,1,0,18.8V4.8A1.324,1.324,0,0,1,1.3,3.5H4.8a.8.8,0,1,1,0,1.6H1.5V18.6H15V15.4a.789.789,0,0,1,.8-.8.736.736,0,0,1,.8.8v3.5A1.484,1.484,0,0,1,15.3,20.1Z"
            transform="translate(0 -0.011)"
            :class="fillClass + ' transition-fill'"
        />
        <path
            id="Pfad_420"
            data-name="Pfad 420"
            d="M8.4,13a1.222,1.222,0,0,1-.9-.4,1.209,1.209,0,0,1-.3-1l.2-2.3a1.8,1.8,0,0,1,.4-.9L16,.4A1.377,1.377,0,0,1,17.8.3L20,2.5a1.272,1.272,0,0,1-.1,1.8l-8.2,8.1a1.268,1.268,0,0,1-.9.4L8.5,13Zm.3-1.2ZM17,1.6l-8.1,8-.2,1.9,2-.2,8-7.9Zm.1-.1Z"
            transform="translate(0 -0.011)"
            :class="fillClass + ' transition-fill'"
        />
    </svg>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "EditIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
    },
});
</script>
