import { PRODUCTS_TYPES } from "../types";
import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";

const state = {
    products: [
      ],
      chosenProduct: null,
    bookmarks_count: 0,
};
const getters = {
    [PRODUCTS_TYPES.GET_PRODUCTS]: (state) => state.products,
    [PRODUCTS_TYPES.GET_CHOSEN_PRODUCT]: (state) => state.chosenProduct,
    [PRODUCTS_TYPES.GET_BOOKMARKS_COUNT]: (state) => state.bookmarks_count,
};
const actions = {
    async [PRODUCTS_TYPES.FETCH_PRODUCTS]({ commit }) {
        const response = await ApiService.query(`/machines`);
        if (getData(response, "data")) {
            commit(`${PRODUCTS_TYPES.SET_PRODUCTS}`, getData(response, "data"));
        }
    },
    async [PRODUCTS_TYPES.FETCH_SINGLE_PRODUCT](_1, productID) {
        const response = await ApiService.query(`/machines/` + productID);
        if (getData(response, "data")) {
            let product = getData(response, "data");
            return product;
        }
    },
    async [PRODUCTS_TYPES.GENERATE_BOOKMARK_PDF](_1,bookmarks) {
        const response = await ApiService.post(`/generatePDF`,{bookmarks:bookmarks});
        return response
    },
};
const mutations = {
    [PRODUCTS_TYPES.SET_PRODUCTS]: (state, array) => (state.products = array),
    [PRODUCTS_TYPES.SET_CHOSEN_PRODUCT]: (state, payload) => {state.chosenProduct = payload;},
    [PRODUCTS_TYPES.SET_BOOKMARKS_COUNT]: (state) => {state.bookmarks_count = JSON.parse(localStorage.getItem('bookmarks'));},
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
