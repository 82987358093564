<template>
    <svg
        id="CheckOnly"
        xmlns="http://www.w3.org/2000/svg"
        width="15.701"
        height="11.63"
        viewBox="0 0 15.701 11.63"
    >
        <path
            id="Pfad_305"
            data-name="Pfad 305"
            d="M147.3,52.547a1.006,1.006,0,0,1-.7-.282l-4.784-4.612A1.009,1.009,0,1,1,143.21,46.2l4.046,3.9,8.208-8.859a1.008,1.008,0,1,1,1.472,1.377l-8.9,9.61a1,1,0,0,1-.708.321Z"
            transform="translate(-141.506 -40.917)"
            :class="fillClass + ' transition-fill-opacity'"
        />
    </svg>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "CheckedIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
    },
});
</script>
