<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.577"
    height="24.005"
    viewBox="0 0 12.577 24.005"
  >
    <path
      id="facebook"
      d="M12.462.173V3.981H10.2a1.964,1.964,0,0,0-.264-.017A2.11,2.11,0,0,0,8.522,4.5l0,0a2.368,2.368,0,0,0-.439,1.381c0,.062,0,.124.007.185V8.784h4.229l-.56,4.27H8.1V24H3.681V13.053H0V8.784h3.68V5.639c-.007-.1-.011-.222-.011-.342a5.56,5.56,0,0,1,1.513-3.82l0,0A5.418,5.418,0,0,1,8.915,0C9,0,9.095,0,9.185,0H9.172c.118,0,.256,0,.4,0a24.124,24.124,0,0,1,3.011.188L12.461.175Z"
      transform="translate(-0.001 0.005)"
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ArrowIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>
