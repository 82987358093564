<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
    >
        <g data-name="Gruppe 324" transform="translate(-630 -553)">
            <circle
                data-name="Ellipse 101"
                cx="9"
                cy="9"
                r="9"
                transform="translate(630 553)"
                :class="fillClass + ' transition-fill'"
            />
            <rect
                data-name="Rechteck 1001"
                width="2"
                height="6"
                rx="0.5"
                transform="translate(638 557)"
                :class="bgClass"
            />
            <rect
                data-name="Rechteck 1002"
                width="2"
                height="2"
                rx="0.5"
                transform="translate(638 565)"
                :class="bgClass"
            />
        </g>
    </svg>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "ErrorIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
        bgClass: {
            type: String,
            required: true,
        },
    },
});
</script>
