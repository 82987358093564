import { createRouter, createWebHistory } from "vue-router";
// import store from "../store";
// import { AUTH_TYPES, STORE_TYPES } from "../store/types";

const routes = [
    {
        path: "/",
        name: "ProductSelection",
        component: () => import("../views/productSelection/Index.vue"),
        meta: {
            requiresAdmin: true,
            isLoggedIn: true,
        },
    },
    {
        path: "/configurator/:ID",
        name: "Configurator",
        props:true,
        component: () => import("../views/configureProduct/Index.vue"),
        meta: {
            requiresAdmin: true,
            isLoggedIn: true,
        },
    },
    // {
    //     path: "/bookmarks",
    //     name: "Bookmarks",
    //     component: () => import("../views/bookmarks/Index.vue"),
    //     meta: {
    //         requiresAdmin: true,
    //         isLoggedIn: true,
    //     },
    // },
    {
        path: "/contact",
        name: "Contact",
        component: () => import("../views/contact/Index.vue"),
        meta: {
            requiresAdmin: true,
            isLoggedIn: true,
        },
    },
    {
        path: "/settings",
        name: "Settings",
        component: () => import("../views/settings/Index.vue"),
        meta: {
            requiresAdmin: true,
            isLoggedIn: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
// router.beforeEach(async (to, from, next) => {
//     // const isAdmin = true;
//     const isLoggedIn =
//         store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.IS_LOGGED_IN}`];

//     // const routeRequiresAdmin = to.matched.some(
//     //     (record) => record.meta.requiresAdmin
//     // );
//     const routeRequiresLogIn = to.matched.some(
//         (record) => record.meta.isLoggedIn
//     );
//     if (to.name == "TEst") {
//         console.log("test");
//     } else if (!isLoggedIn && routeRequiresLogIn) {
//         next({ name: "LOGIN" });
//         return;
//     } else if (isLoggedIn && !routeRequiresLogIn) {
//         next({ name: "Dashboard" });
//     }
//     next();
// });
export default router;
