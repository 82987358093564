import { createI18n } from "vue-i18n";
import store from "../store";
import { HELPERS_TYPES, STORE_TYPES } from "../store/types";

const language = {
    de: {
        error: "Fehler",
        send_error_message:"Beim Versenden der Merkliste ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
        goToHomepage: "gym80 Website",
        productSelection: "Produktauswahl",
        myBookmarks: "Meine Merkliste",
        contact: "Kontakt",
        productConfiguration:"Produkte zur Konfiguration im Überblick",
        filter: "Filter",
        upperBody: "Oberkörper",
        lowerBody: "Unterkörper",
        torso: "Rumpf",
        configureNow: "Jetzt konfigurieren",
        viewConfiguration: "Konfiguration ansehen",
        sendRequest: "Anfrage senden",
        paymentInfo: "Es gelten unsere Allgemeinen Zahlungs- und Lieferbedingungen. Diese sind auf gym80.de einzusehen.",
        goToBookmarks: "Zur Merkliste",
        height: "Höhe",
        width: "Breite",
        length: "Länge",
        totalWeight: "Gesamt- gewicht",
        normalWeightblock: "Standard Gewichtsblock",
        upholstery: "Polster",
        upholsteryInfo: "Wählen Sie aus 18 Polster-Standard­­farben. Sonderwünsche gerne auf Anfrage.",
        frame: "Rahmen",
        frameInfo: "Rahmen- und Verkleidungsfarben ­kannst du frei kombinieren. Vier Standardfarben (glänzend) stehen dir ­dafür zur Aus­wahl.",
        embroidery: "Bestickung",
        embroideryInfo: "Das Polster kann individuell mit deinem Logo bestickt werden.",
        addEmbroidery: "Füge hier dein Logo ein oder ",
        search: "suche",
        sendRequestInfo: "Jetzt Anfrage senden",
        saved: "Gespeichert",
        configurator_loading: "Der Konfigurator wird geladen...",
        editor_loading: "Der Editor wird geladen...",
        cancel: "Abbrechen",
        sendToGym: "Merkliste senden",
        send: "Absenden",
        firstName: "Vorname",
        lastName: "Nachname",
        email: "E-Mail Adresse",
        street: "Straße",
        houseNumber: "Hausnummer",
        phone: "Telefonnummer",
        postcode:"PLZ",
        location: "Ort",
        nation: "Land",
        dataProtection: "Datenschutz",
        dataText1: "Ja, ich habe die Informationen zum ",
        dataText2: "gelesen und akzeptiere diese.",
        privacyPolicy: "Datenschutz",
        products_loading: "Produkte werden geladen...",
        remove_image: "Logo entfernen",
        edit_image: "Logo bearbeiten",
        save_image: "Logo speichern",
        addImageInfoText: "Hier hast du die Möglichkeit, deine Grafik / dein Logo anzupassen. Für ein optimales Resultat zentrieren deine Grafik / dein Logo in der Mitte mit genügend Abstand zu den Rändern.",
        crop_image: "Bild zuschneiden",
        saving_bookmarks: "Wird gespeichert...",
        save: "Speichern",
        saveImageInfoText: "Bist du mit deinem Logo zufrieden? Dann speicher es nun ab und schaue dir das Ergebnis in dem Modell an.",
        empty_bookmarks:"Ihre gym80-Merkliste ist leer",
        close:"Schließen",
        covering:"Verkleidung/bewegliche Teile",
        special: "Spezial"
    },
    en: {
        special: "Special",
        covering:"Covering/movable parts",
        error: "Error",
        close:"Close",
        send_error_message:"There was an error while trying to send your request. Please try again later.",
        goToHomepage: "gym80 Website",
        productSelection: "Product selection",
        myBookmarks: "My bookmarks",
        contact: "Contact",
        productConfiguration:"Available products for configuration",
        filter: "Filter",
        upperBody: "Upper body",
        lowerBody: "Lower body",
        torso: "Torso",
        configureNow: "Configure now",
        viewConfiguration: "View configuration",
        sendRequest: "Send request",
        paymentInfo: "Our general terms of payment and delivery apply. These can be viewed at gym80.de.",
        goToBookmarks: "Add to bookmarks",
        height: "height",
        width: "width",
        length: "length",
        totalWeight: "total weight",
        normalWeightblock: "standard weight block",
        upholstery: "Upholstery",
        upholsteryInfo: "Choose between 18 different upholstery colors. Special colors are available on request.",
        frame: "Frame",
        frameInfo: "Frame colors can be chosen individually. There are four available (glossy) colors you can choose from.",
        embroidery: "Embroidery",
        embroideryInfo: "We can individually laser or embroider your graphic / logo on the upholstery.",
        addEmbroidery: "Add your image here or ",
        search: "search",
        sendRequestInfo: "Send your request now",
        saved: "Saved",
        configurator_loading: "Configurator is loading...",
        editor_loading: "Editor is loading...",
        cancel: "Cancel",
        sendToGym: "Send bookmarks to gym80",
        send: "Send",
        firstName: "First name",
        lastName: "Last name",
        email: "E-Mail address",
        street: "Street",
        houseNumber: "House number",
        phone: "Phone number",
        postcode:"Postcode",
        location: "City",
        nation: "Country",
        dataProtection: "Privacy policy",
        dataText1: "I have read and accept the  ",
        dataText2: ".",
        privacyPolicy: "privacy policy",
        products_loading: "Products are loading...",
        remove_image: "Remove image",
        edit_image: "Edit image",
        save_image: "Save image",
        addImageInfoText: "Now you can adjust your graphic / logo to fit perfectly on the machine. For a perfect fit drag your graphic / logo into the center of the canvas with enough space to the border of the canvas.",
        crop_image: "Crop image",
        saving_bookmarks: "Saving bookmark...",
        save: "Save",
        saveImageInfoText: "Are you happy with your current logo? Go take a look at it on your chosen model.",
        empty_bookmarks:"Your bookmarks are currently empty"
    },
};

let selectedLanguage = store.getters[`${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_SELECTED_LANGUAGE}`];
let i18n = createI18n({
    messages: language,
    locale: selectedLanguage,
    fallbackLocale: "de",
});

// export function setLanguage(data) {
//     i18n.locale = data
//     console.log(i18n.);
// }



export default i18n;
