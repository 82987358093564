<template>
  <div
    class="div xl:h-32 h-24 w-full z-50"
    :class="$route.meta.isLoggedIn ? '' : 'd-none'"
  >
    <nav
      class="
                flex
                w-full
                justify-start
                fixed
                left-0
                top-0
                xl:h-32
                h-24
                bg-black-dark
                pl-10
            "
      style="z-index: 100000"
    >
      <!-- <div
        class="
        mt-7 mr-3
          h-16
          p-4
          transform
          transition-all
          cursor-pointer
          flex
          "
        :class="$route.name == 'Bookmarks' ? 'bg-primary bg-opacity-10' : ''"
        @click="$router.push({ name: 'Bookmarks' }), (state.menuOpen = false)"
      >
        <p class="text-white">{{ $t("myBookmarks") }}</p>

        <div
          class=" bg-primary h-4 w-4 ml-3 mt-1  rounded-full flex justify-center"
        >
          <p class="text-white-dark text-xs font-semibold">
            {{ bookmarks_count.length }}
          </p>
        </div>
      </div> -->
      <div class="flex justify-between w-16 mt-11 mr-16" id="language_select">
        <!-- <GlobeIcon fillClass="fill-white" /> -->
        <p
          class="hover:text-primary absolut cursor-pointer"
          :class="selectedLangauge == 'de' ? 'text-primary' : 'text-white-dark'"
          @click="changeLocale('de')"
        >
          DE
        </p>
        <p class="text-white-dark">|</p>
        <p
          class="hover:text-primary cursor-pointer"
          :class="selectedLangauge == 'en' ? 'text-primary' : 'text-white-dark'"
          @click="changeLocale('en')"
        >
          EN
        </p>
      </div>
      <div
        @click="openInNewTab('https://gym80.de/de/')"
        class="h-24 xl:mt-3 lg:absolute flex mt-1.5 -ml-9 mr-auto cursor-pointer"
        style="left:44%;"
      >
        <img
          src="@/assets/logo.svg"
          alt="Logo"
          class="ml-1 block lg:h-24 h-20 p-3.5"
        />
      </div>
    </nav>
    <div
      v-if="state.menuOpen"
      @click="state.menuOpen = false"
      class="
                bg-black bg-opacity-40
                xl:hidden
                absolute
                h-full
                w-full
                top-0
                left-0
            "
      style="z-index: 1000"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive } from "vue";
import { useStore } from "vuex";
import { STORE_TYPES, HELPERS_TYPES, PRODUCTS_TYPES } from "../../store/types";
import { useI18n } from "vue-i18n";
// import i18nGlobal from "../../common/translation"

export default defineComponent({
  components: {},
  name: "Navbar",
  methods: {
    changeLocale(value) {
      this.$root.$i18n.locale = value;
      localStorage.setItem("selectedLanguage", value);
    },
  },
  setup() {
    const store = useStore();

    const state = reactive({
      showSearch: false,
      selectedPage: "choose_product",
      selectedLanguage: "DE",
      menuOpen: false,
      count: 0,
    });

    const i18n = useI18n();
    /**
     *  Functions
     */
    function SET_LANGUAGE(value) {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LANGUAGE}`,
        value
      );
      localStorage.setItem("selectedLanguage", value);
    }
    function openMenu(event) {
      state.openMenu = event;
    }

    function openInNewTab(url) {
      window.open(url, "_blank");
    }

    /**
     *  Computed
     */
    const selectedLangauge = computed(() => i18n.locale.value);
    const bookmarks_count = computed(() => {
      return store.getters[
        `${STORE_TYPES.PRODUCTS}/${PRODUCTS_TYPES.GET_BOOKMARKS_COUNT}`
      ];
    });

    return {
      //State
      state,
      // Computed
      // Functions
      SET_LANGUAGE,
      selectedLangauge,
      openMenu,
      openInNewTab,
      bookmarks_count,
    };
  },
});
</script>

<style scoped>
.slide-leave-active,
.slide-enter-active {
  transition: 0.1s;
}
/* .slide-enter-from {
    transform: translate(0, 0);
} */
.slide-leave-to {
  transform: translate(-100%, 0);
}
</style>
