export const STORE_TYPES = {
    AUTH: "auth",
    HELPERS: "helpers",
    SEARCH: "search",
    SETTINGS: "settings",
    PRODUCTS: "products",
    VIEWER: "viewer"
};

export const HELPERS_TYPES = {
    /*** GETTERS  ***/
    GET_FILTER_ACTIVE_ROUTE: "GET_FILTER_ACTIVE_ROUTE",
    GET_IS_SIDEBAR_ACTIVE: "GET_IS_SIDEBAR_ACTIVE",
    GET_LOADING_IDS: "GET_LOADING_IDS",
    GET_ERROR_IDS: "GET_ERROR_IDS",
    GET_TOAST_MESSAGE: "GET_TOAST_MESSAGE",
    GET_SELECTED_LANGUAGE: "GET_SELECTED_LANGUAGE",

    /*** MUTATIONS  ***/
    SET_FILTER_ACTIVE_ROUTE: "SET_FILTER_ACTIVE_ROUTE",
    SET_IS_SIDEBAR_ACTIVE: "SET_IS_SIDEBAR_ACTIVE",
    SET_LOADING_IDS: "SET_LOADING_IDS",
    SET_LOADING_ID: "SET_LOADING_ID",
    REMOVE_LOADING_ID: "REMOVE_LOADING_ID",
    SET_ERROR_IDS: "SET_ERROR_IDS",
    SET_ERROR_ID_OBJECT: "SET_ERROR_ID_OBJECT",
    REMOVE_ERROR_ID: "REMOVE_ERROR_ID",
    SET_TOAST_MESSAGE: "SET_TOAST_MESSAGE",
    SET_LANGUAGE: "SET_LANGUAGE",
    /*** ACTIONS  ***/
    SAVE_USER_IMAGES: "SAVE_USER_IMAGES",
    DISPLAY_IMAGE: "DISPLAY_IMAGE",
    DELETE_IMAGES: "DELETE_IMAGES",
    SEND_BOOKMARKS:"SEND_BOOKMARKS",
};

export const AUTH_TYPES = {
    /*** GETTERS  ***/
    GET_USER: "GET_USER",
    IS_LOGGED_IN: "IS_LOGGED_IN",
    /*** MUTATIONS  ***/

    /*** ACTIONS  ***/
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    RESET_PASSWORD: "RESET_PASSWORD",
    RESEND_2FA_CODE: "RESEND_2FA_CODE",
    SEND_2FA_CODE: "SEND_2FA_CODE",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    REGISTER: "REGISTER",
    FETCH_USER: 'FETCH_USER',
};

export const SEARCH_TYPES = {
    /*** GETTERS  ***/
    GET_IS_SEARCH_ACTIVE: "GET_IS_SEARCH_ACTIVE",
    GET_SEARCH: "GET_SEARCH",
    GET_SEARCH_ITEMS: "GET_SEARCH_ITEMS",

    /*** MUTATIONS  ***/
    SET_IS_SEARCH_ACTIVE: "SET_IS_SEARCH_ACTIVE",
    SET_SEARCH: "SET_SEARCH",
    SET_SEARCH_ITEMS: "SET_SEARCH_ITEMS",

    /*** ACTIONS  ***/
};

export const SETTINGS_TYPE = {
    /*** ACTIONS  ***/
    FETCH_SETTINGS: "FETCH_SETTINGS",
};
export const PRODUCTS_TYPES = {
    /*** GETTERS  ***/
    GET_CHOSEN_PRODUCT: "GET_CHOSEN_PRODUCT",
    GET_PRODUCTS: "GET_PRODUCTS",
    GET_BOOKMARKS_COUNT: "GET_BOOKMARKS_COUNT",

    /*** MUTATIONS  ***/
    SET_CHOSEN_PRODUCT: "SET_CHOSEN_PRODUCT",
    SET_PRODUCTS: "SET_PRODUCTS",
    SET_BOOKMARKS_COUNT: "SET_BOOKMARKS_COUNT",

    /*** ACTIONS  ***/
    FETCH_PRODUCTS: "FETCH_PRODUCTS",
    FETCH_SINGLE_PRODUCT: "FETCH_SINGLE_PRODUCT",
    GENERATE_BOOKMARK_PDF: "GENERATE_BOOKMARK_PDF",
};

export const VIEWER_TYPES = {
    GET_MODEL: "GET_MODEL",
    GET_SCENE: "GET_SCENE",
    GET_SCENE_2: "GET_SCENE_2",
    GET_MATERIALS: "GET_MATERIALS",
    GET_MATERIALS_2: "GET_MATERIALS_2",
    GET_FINISHED: "GET_FINISHED",

    LOAD_SCENE: "LOAD_SCENE",

    SET_SCENE: "SET_SCENE",
    SET_MODEL: "SET_MODEL",
    SET_MATERIALS: "SET_MATERIALS",
}
