import { SEARCH_TYPES } from "../types";

const state = {
    isSearchActive: false,
    search: "",
    searchItems: [
        {
            name: "Kategorie 1",
            items: [
                {
                    id: 1,
                    text: "Max Mustermann",
                },
                {
                    id: 2,
                    text: "Bernd Brot",
                },
                {
                    id: 3,
                    text: "Fred Schneider",
                },
            ],
        },
        {
            name: "Kategorie 2",
            items: [
                {
                    id: 1,
                    text: "Volkswagen AG",
                },
                {
                    id: 2,
                    text: "Vivawest",
                },
                {
                    id: 3,
                    text: "SpaceX",
                },
            ],
        },
        {
            name: "Kategorie 3",
            hasImages: true,
            items: [
                {
                    id: 1,
                    text: "David",
                    image: "/img/example_profile.png",
                },
            ],
        },
    ],
};
const getters = {
    [SEARCH_TYPES.GET_IS_SEARCH_ACTIVE]: (state) => state.isSearchActive,
    [SEARCH_TYPES.GET_SEARCH]: (state) => state.search,
    [SEARCH_TYPES.GET_SEARCH_ITEMS]: (state) => {
        let items = JSON.parse(JSON.stringify(state.searchItems));
        return items.filter((category) => {
            category.items = category.items.filter((item) => {
                return item.text
                    .toLowerCase()
                    .match(state.search.toLowerCase());
            });
            return (
                category.items.length > 0 ||
                category.name.toLowerCase().match(state.search.toLowerCase())
            );
        });
    },
};
const actions = {};
const mutations = {
    [SEARCH_TYPES.SET_IS_SIDEBAR_ACTIVE]: (state, value) =>
        (state.isSidebarActive = value),
    [SEARCH_TYPES.SET_SEARCH]: (state, value) => (state.search = value),
    [SEARCH_TYPES.SET_SEARCH_ITEMS]: (state, value) =>
        (state.searchItems = value),
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
