<template>
    <svg
        id="E-Mail"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="16"
        viewBox="0 0 24.763 16.08"
    >
        <path
            id="Pfad_362"
            data-name="Pfad 362"
            d="M23.409,16.08H1.354A1.356,1.356,0,0,1,0,14.726V1.354A1.356,1.356,0,0,1,1.354,0H23.409a1.356,1.356,0,0,1,1.354,1.354V14.726a1.356,1.356,0,0,1-1.354,1.354M1.621,14.459H23.142V1.621H1.621Z"
            :class="fillClass + ' transition-fill'"
        />
        <path
            id="Pfad_363"
            data-name="Pfad 363"
            d="M12.342,10.844a1.355,1.355,0,0,1-.879-.324L.788,1.428A.81.81,0,1,1,1.84.195l10.5,8.943L22.843.195a.81.81,0,0,1,1.05,1.233L13.218,10.52a1.353,1.353,0,0,1-.876.324"
            transform="translate(0.041 0)"
            :class="fillClass + ' transition-fill'"
        />
    </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "MailIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
    },
});
</script>
