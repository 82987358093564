import { AUTH_TYPES } from "../types";
import router from "../../router";
import { getCookie, removeCookie, setCookie } from "../../common/cookie";
import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";
const state = {
    user: {
        id: 1,
        name: "Max Mustermann",
    },
};

const getters = {
    [AUTH_TYPES.GET_USER](state) {
        return state.user;
    },
    [AUTH_TYPES.IS_LOGGED_IN]() {
        return getCookie("logged_in");
    },
};

const actions = {
    async [AUTH_TYPES.LOGIN](context, { user }) {
        let expiringDays = 1;
        if (user.stay_logged_in) {
            expiringDays = 365;
        }
        const response = await ApiService.getCookies().then(() => {
            return ApiService.post("login", user);
        });
        // TODO enablen if backend connected
        if (getData(response, "status") === 200) {
        setCookie("logged_in", true, expiringDays);
        context.dispatch(AUTH_TYPES.FETCH_USER)
        router.push({ name: "ProductSelection" });
        } else if(getData(response, "response.status") === 423)
        {
        router.push({ name: "TwoFactorCode", params: {email: user.email , password: user.password} });
        }
        return;
    },
    async [AUTH_TYPES.RESET_PASSWORD]( context , { user }) {
        user.redirect_url="https://app.Gym80.de:8080/password-change"
        const response = await ApiService.post("resetPassword",user);
        console.log(response);
    },
    async [AUTH_TYPES.REGISTER](context, { user }) {
        let expiringDays = 1;
        if (user.stay_logged_in) {
            expiringDays = 365;
        }
        const response = await ApiService.post("register",user);
        if (getData(response, "status") === 200) {
            
        setCookie("logged_in", true, expiringDays);
            router.push({ name: "ProductSelection" });
            }
        return;
    },
    async [AUTH_TYPES.SEND_2FA_CODE]({ state }, { twoFactorItem }) {
        console.log("send code");
        console.log(twoFactorItem);
        console.log(state);
    },
    async [AUTH_TYPES.RESEND_2FA_CODE]() {
        console.log("resend code");
    },
    async [AUTH_TYPES.CHANGE_PASSWORD](ctx, { user }) {
        let expiringDays = 1;
        if (user.stay_logged_in) {
            expiringDays = 365;
        }
        const response = await ApiService.post("changePassword",user);
        if (getData(response, "status") === 200) {
            setCookie("logged_in", true, expiringDays);
            router.push({ name: "ProductSelection" });
            }
        return response;
    },
    async [AUTH_TYPES.FETCH_USER]({state}) {
        const response = await ApiService.query("user");
        state.user = response.data
        console.log(response);
        return response;
    },
    async [AUTH_TYPES.LOGOUT]() {
        if (getCookie("logged_in")) {
            removeCookie("logged_in");
            ApiService.post("logout");
        }
        router.push({ name: "Login" });
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
