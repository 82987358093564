<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.993"
        height="19.38"
        viewBox="0 0 21.993 19.38"
    >
        <g id="Filter" transform="translate(0.765 0.75)">
            <path
                id="Pfad_407"
                data-name="Pfad 407"
                d="M8.6,18.143V11.1a.481.481,0,0,0-.108-.3L.858,1.525A.474.474,0,0,1,1.225.75H20.738a.474.474,0,0,1,.365.775L13.47,10.794a.475.475,0,0,0-.108.3v4.038a.473.473,0,0,1-.222.4L9.325,18.545A.474.474,0,0,1,8.6,18.143Z"
                transform="translate(-0.749 -0.75)"
                fill="none"
                :class="[fillClass, strokeClass, 'transition-stroke']"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="1.5"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "FilterIcon",
    props: {
        fillClass: {
            type: String,
            required: false,
            default: "fill-transparent",
        },
        strokeClass: {
            type: String,
            required: true,
        },
    },
});
</script>
