<template>
  <navbar></navbar>
  <div v-if="$route.meta.isLoggedIn">
    <!-- <sidebar></sidebar> -->
    <main>
      <router-view
        class="routerview transition-all scrollbar overflow-y-auto"
      />
    </main>
  </div>
  <div v-else>
    <router-view />
  </div>
  <loading-animation />
</template>
<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { STORE_TYPES, HELPERS_TYPES, AUTH_TYPES, PRODUCTS_TYPES } from "./store/types";
import Navbar from "./components/layout/Navbar.vue";
// import Sidebar from "./components/layout/Sidebar.vue";
import LoadingAnimation from "./components/global/LoadingAnimation.vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  components: {Navbar, LoadingAnimation },
  methods:{
    changeLocale(value) {
            this.$root.$i18n.locale = value;
        }
  },
  setup() {
    const store = useStore();
        const i18n = useI18n();
    /**
     *  Computed
     */
    const GET_IS_SIDEBAR_ACTIVE = computed(
      () =>
        store.getters[
          `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_IS_SIDEBAR_ACTIVE}`
        ]
    );

    const IS_LOGGED_IN = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.IS_LOGGED_IN}`]
    );

    function FETCH_USER() {
      if (IS_LOGGED_IN.value == "true") {
        store.dispatch(`${STORE_TYPES.AUTH}/${AUTH_TYPES.FETCH_USER}`);
      }
    }
    FETCH_USER()
    if(localStorage.getItem("selectedLanguage"))
    {
      let value = localStorage.getItem("selectedLanguage")
            i18n.locale.value = value
    }
    if(!localStorage.getItem("bookmarks"))
    {
      localStorage.setItem("bookmarks",JSON.stringify([]));
    }
    store.commit(`${STORE_TYPES.PRODUCTS}/${PRODUCTS_TYPES.SET_BOOKMARKS_COUNT}`)
    return {
      GET_IS_SIDEBAR_ACTIVE,
      FETCH_USER,
      IS_LOGGED_IN
    };
  },
});
</script>

<style>


.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  display: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #303440;
  opacity: 0.2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e001b;
  border-radius: 5px;
  display: hidden;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dc0030;
}
</style>

<style lang="scss" scoped>
.routerview {
  height: calc(100vh - 112px);
  @screen lg {
    max-height: calc(100vh - 64px);
  }
}
</style>
