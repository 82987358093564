<template>
    <transition name="fade">
        <slot></slot>
    </transition>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({});
</script>
<style>
.fade-enter-from,
.fade-leave-to {
    opacity: 0 !important;
}
</style>
