import { createStore } from "vuex";
import auth from "./modules/auth";
import helpers from "./modules/helpers";
import search from "./modules/search";
import settings from "./modules/settings";
import products from "./modules/products";
import viewer from "./modules/viewer"
export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        helpers,
        search,
        settings,
        products,
        viewer
    },
});
