import { BABYLON } from "vue-babylonjs";
import store from "../store";
import { STORE_TYPES, PRODUCTS_TYPES, HELPERS_TYPES } from "../store/types";
import Logo from "../../public/img/icons/DefaultLogo.png"


export async function createScene(engine, canvas) {
    // This creates a basic Babylon Scene object (non-mesh)
    let scene = new BABYLON.Scene(engine);
    // scene.clearColor = BABYLON.Color3.White();
    // await engine.displayLoadingUI();

    // create camera and lights for scene
    const lights = {};
    const env = {};
    let envFile = process.env.VUE_APP_ENVIRONMENT
    async function initScene() {
        const camera = new BABYLON.ArcRotateCamera(
            "camera",
            BABYLON.Tools.ToRadians(90),
            BABYLON.Tools.ToRadians(90),
            1.2,
            new BABYLON.Vector3(0.0, 0.1, 0.0),
            scene
        );
        camera.minZ = 0.01;
        camera.panningInertia = 0.65;
        camera.panningSensibility = 10;
        camera.wheelPrecision = 10;
        camera.pinchDeltaPercentage=0.003;
        camera.wheelDeltaPercentage = 0.01;
        camera.upperRadiusLimit = 1.5;
        camera.lowerRadiusLimit = 0.25;
        camera.upperBetaLimit = 1.575;
        camera.lowerBetaLimit = 0;
        camera.panningAxis = new BABYLON.Vector3(0, 0, 0);
        camera.attachControl(canvas, true);

        // env.lighting = new BABYLON.HDRCubeTexture(
        //     "./3d/environment.hdr",
        //     scene,
        //     128,
        //     false,
        //     true,
        //     false,
        //     true
        // );
        env.lighting = BABYLON.CubeTexture.CreateFromPrefilteredData(
            envFile,
            scene
        );
        env.lighting.name = "hamburg_hbf";
        env.lighting.gammaSpace = false;
        env.lighting.rotationY = BABYLON.Tools.ToRadians(0);
        scene.environmentTexture = env.lighting;

        // env.skybox = BABYLON.MeshBuilder.CreateBox(
        //     "box",
        //     { size: 500.0 },
        //     scene
        // );
        // env.skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene);
        // env.skyboxMaterial.backFaceCulling = false;
        // env.skyboxMaterial.reflectionTexture = new BABYLON.HDRCubeTexture(
        //     envFile,
        //     scene,
        //     16,
        //     false,
        //     true,
        //     false,
        //     true
        // );
        // env.skyboxMaterial.anisotropicFilteringLevel = 1;
        // env.skyboxMaterial.roughness = 7;
        // env.skyboxMaterial.reflectionTexture.coordinatesMode =
        //     BABYLON.Texture.SKYBOX_MODE;
        // env.skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
        // env.skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
        // env.skyboxMaterial.intensity = 5;
        // env.skybox.material = env.skyboxMaterial;
        scene.clearColor = new BABYLON.Color3(0.85,0.85,0.85);
        lights.dirLight = new BABYLON.DirectionalLight(
            "dirLight",
            new BABYLON.Vector3(0.2, -0.7, 0.23),
            scene
        );
        lights.dirLight.position = new BABYLON.Vector3(-0.05, 0.35, -0.05);
        lights.dirLight.shadowMaxZ = 0.45;
        lights.dirLight.intensity = 1;
    }

    // const bottle = {};
    const table = {};
    // let ground = {}
    async function loadMeshes() {
        
    // ground = BABYLON.Mesh.CreateGround("ground1", 6, 6, 2, scene);
    // ground.material = new BABYLON.StandardMaterial("groundMat",scene);
    // ground.material.diffuseColor = new BABYLON.Color3.White();
    // ground.overlayColor  =  new BABYLON.Color3(0.80, 0.80, 0.80);
        table.file = await BABYLON.SceneLoader.AppendAsync(
            "https://patrickryanms.github.io/BabylonJStextures/Demos/sodaBottle/assets/gltf/table.gltf"
        );
        table.mesh = scene.getMeshByName("table_low");
        table.mesh.position = new BABYLON.Vector3(0, -0.005, 0);
        table.mesh.scaling.x = 15
        table.mesh.scaling.y = 1
        table.mesh.scaling.z = 15
        // bottle.root.position = new BABYLON.Vector3(-0.09, 0.0, -0.09);
        // bottle.root.rotation = new BABYLON.Vector3(0.0, 4.0, 0.0);
        table.material = scene.getMaterialByID("tableMat")
        table.material.albedoColor = new BABYLON.Color3(2, 2, 2)
        lights.dirLight.includedOnlyMeshes.push(table.mesh);
    }


    BABYLON.NodeMaterial.IgnoreTexturesAtLoadTime = true;
    let gymModel = null;



    let chosenProduct = store.getters[`${STORE_TYPES.PRODUCTS}/${PRODUCTS_TYPES.GET_CHOSEN_PRODUCT}`]
    // console.log(chosenProduct);
    // let productPath = chosenProduct.file_path.substring(7)
    // let filePath = process.env.VUE_APP_MODEL_FILE + productPath
    // console.log(filePath);
    const shadows = {};
    async function renderGym80Model() {
        var box = new BABYLON.Mesh("box", scene);

        // var assetsManager = new BABYLON.AssetsManager(scene);
        gymModel = await BABYLON.SceneLoader.ImportMesh(
            "",
            "",
            chosenProduct.model,
            scene,
            function (meshes) {
                for (var i = 0; i < meshes.length; i++) {
                    if (meshes[i].parent == null) meshes[i].parent = box;

                    shadows.shadowGenerator
                        .getShadowMap()
                        .renderList.push(meshes[i]);
                    meshes[i].receiveShadows = true;
                }
                box.scaling = new BABYLON.Vector3(0.002, 0.002, 0.002);
            },
            undefined,
            undefined,
            ".gltf"
        );
        gymModel.size = 0.5;
        console.log("FINISHED");
        return;
    }

    function generateShadows() {
        shadows.shadowGenerator = new BABYLON.ShadowGenerator(
            1024,
            lights.dirLight
        );
        shadows.shadowGenerator.useBlurExponentialShadowMap = true;
        shadows.shadowGenerator.blurBoxOffset = 2;
        shadows.shadowGenerator.depthScale = 2;

        // shadows.shadowGenerator.addShadowCaster(bottle.liquid);

        shadows.shadowGenerator.enableSoftTransparentShadow = true;
        shadows.shadowGenerator.transparencyShadow = true;
        // ground.receiveShadows = true;
        // ground.material.environmentIntensity = 0.1;
        table.mesh.receiveShadows = true;
        table.mesh.material.environmentIntensity = 0.2;
    }

    initScene();
    console.log("INIT SUCCESS");
    await loadMeshes();
    console.log("LOADED MESHES");
    // await loadTexturesAsync();
    console.log("LOADED TEXTURES");
    // await createMaterials();
    console.log("LOADED MATERIALS");

    generateShadows();
    await renderGym80Model();
    let product = store.getters[`${STORE_TYPES.PRODUCTS}/${PRODUCTS_TYPES.GET_CHOSEN_PRODUCT}`]

    scene.onReadyObservable.add(async () => {
        let material,material2,material3 ;
        if(scene.getMaterialByID("Upholstery-1"))
        {
         material = scene.getMaterialByID("Upholstery-1");
        // material.albedoColor = BABYLON.Color3.FromHexString('#ffffff')
        // material.roughness = 0.1;
        material.environmentIntensity = 1;
        }
        material2 = scene.getMaterialByID("Base-1");
        material2.roughness = 0.4;
        material2.metallic = 0.2
        console.log(material2);
        material3 = scene.getMaterialByID("Base-2");
        material3.roughness = 0.3;
        console.log(material3);
        // material.albedoColor = BABYLON.Color3.FromHexString('#ffffff')
        material3.environmentIntensity = 1;
        if (product.colors) {
            product.colors.forEach(color => {
                material = scene.getMaterialByID(color.id)
                material.albedoColor = BABYLON.Color3.FromHexString(color.value)
            });
        }
        if (product.croppedImage) {
            let material = scene.getMaterialByID("customer_logo");
            let image = await store.dispatch(`${STORE_TYPES.HELPERS}/${HELPERS_TYPES.DISPLAY_IMAGE}`,product.croppedImage);
            let texture = new BABYLON.Texture(
                image,
                scene,
                true,
                false
            );
            texture.hasAlpha = true;
            material.albedoTexture = texture;
            material.albedoColor = new BABYLON.Color3(1, 1, 1);
        } else {
            let material = scene.getMaterialByID("customer_logo");
            let image = Logo;
            let texture = new BABYLON.Texture(
                image,
                scene,
                true,
                false
            );
            texture.hasAlpha = true;
            material.albedoTexture = texture;
            // material.albedoColor = new BABYLON.Color3(1, 1, 1);
        }
    });
    return scene;
}
