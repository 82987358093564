<template>
    <svg
        id="Exit"
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 12.982 12.982"
    >
        <rect
            id="Rechteck_973"
            data-name="Rechteck 973"
            width="1.574"
            height="16.785"
            rx="0.5"
            transform="translate(0 1.113) rotate(-45)"
            :class="fillClass + ' transition-fill-opacity'"
        />
        <rect
            id="Rechteck_974"
            data-name="Rechteck 974"
            width="1.574"
            height="16.785"
            rx="0.5"
            transform="translate(11.869 0) rotate(45)"
            :class="fillClass + ' transition-fill-opacity'"
        />
    </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "CancelIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
    },
});
</script>
