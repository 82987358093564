/**
 * Nullsafe Method of retrieving nested data
 * e.g.: (object, "sub_object.id") returns id of the sub_object OR null
 */
export function getData(data, columns) {
    if (data == null) return null;
    const columnsArray = columns.split(".");
    let resultData = data;
    columnsArray.forEach((column) => {
        if (resultData != null && resultData[column] != null) {
            resultData = resultData[column];
        } else {
            resultData = null;
        }
    });
    return resultData;
}

export function isDataInArray(array, element) {
    if (!array || !element || !element.id) return false;

    let isInArray = false;
    for (var i = 0; i < array.length; i++) {
        if (array[i].id == element.id) {
            isInArray = true;
            break;
        }
    }

    return isInArray;
}

export function formatDataToRequest(object, prefix = "") {
    if (prefix != "" && prefix.slice(-1) == "]") {
        prefix += ".";
    } else if (prefix != "" && prefix.slice(-1) != "[") {
        prefix += "_";
    }
    let newObject = {};

    for (const key in object) {
        const element = object[key];
        let prefixKey = "";
        if (prefix.slice(-1) == "[") {
            prefixKey = prefix + key + "]";
        } else {
            prefixKey = prefix + key;
        }
        if (typeof element == "object") {
            // is Array
            if (element && element.length >= 0) {
                newObject = Object.assign(
                    newObject,
                    formatDataToRequest(element, prefixKey + "[")
                );
            } else {
                newObject = Object.assign(
                    newObject,
                    formatDataToRequest(element, prefixKey)
                );
            }
        } else {
            newObject[prefixKey] = element;
        }
    }

    return newObject;
}
