<template>
    <svg
        id="Settings"
        xmlns="http://www.w3.org/2000/svg"
        width="19.5"
        height="19.5"
        viewBox="0 0 19.5 19.5"
    >
        <path
            id="Pfad_306"
            data-name="Pfad 306"
            d="M169.748,139.75h-1.22a1.191,1.191,0,0,1-1.112-.851l-.251-.775a1.65,1.65,0,0,0-.966-1.028,1.561,1.561,0,0,0-1.285.056l-.891.455a1.19,1.19,0,0,1-1.388-.183l-.863-.863a1.188,1.188,0,0,1-.184-1.389l.37-.724a1.655,1.655,0,0,0,.043-1.417,1.556,1.556,0,0,0-.945-.862l-.954-.309a1.19,1.19,0,0,1-.852-1.112v-1.22a1.191,1.191,0,0,1,.851-1.112l.775-.251a1.65,1.65,0,0,0,1.028-.966,1.561,1.561,0,0,0-.056-1.285l-.455-.891a1.19,1.19,0,0,1,.184-1.389l.862-.862a1.19,1.19,0,0,1,1.389-.184l.724.37a1.652,1.652,0,0,0,1.417.043,1.556,1.556,0,0,0,.862-.945l.309-.954a1.19,1.19,0,0,1,1.112-.852h1.22a1.191,1.191,0,0,1,1.112.851l.251.775a1.647,1.647,0,0,0,.966,1.028,1.565,1.565,0,0,0,1.285-.056l.891-.455a1.188,1.188,0,0,1,1.388.183l.863.863a1.188,1.188,0,0,1,.184,1.389l-.37.724a1.655,1.655,0,0,0-.043,1.417,1.556,1.556,0,0,0,.945.862l.954.309a1.19,1.19,0,0,1,.852,1.112v1.22a1.191,1.191,0,0,1-.851,1.112l-.775.251a1.65,1.65,0,0,0-1.028.966,1.561,1.561,0,0,0,.056,1.285l.455.891a1.192,1.192,0,0,1-.183,1.388l-.863.863a1.185,1.185,0,0,1-1.389.184l-.724-.37a1.659,1.659,0,0,0-1.417-.043,1.556,1.556,0,0,0-.862.945l-.309.954A1.19,1.19,0,0,1,169.748,139.75Zm-.965-1.5h.71l.25-.769a3.049,3.049,0,0,1,1.692-1.859,3.144,3.144,0,0,1,2.694.085l.551.28.5-.5-.366-.717a3.057,3.057,0,0,1-.116-2.516,3.146,3.146,0,0,1,1.961-1.844l.589-.191v-.71l-.769-.25a3.049,3.049,0,0,1-1.859-1.692,3.151,3.151,0,0,1,.085-2.694l.28-.551-.5-.5-.717.366a3.052,3.052,0,0,1-2.516.116,3.142,3.142,0,0,1-1.843-1.961l-.192-.589h-.71l-.25.769a3.049,3.049,0,0,1-1.692,1.859,3.154,3.154,0,0,1-2.694-.085l-.551-.28-.5.5.366.717a3.057,3.057,0,0,1,.116,2.516,3.146,3.146,0,0,1-1.961,1.844l-.589.191v.71l.769.25a3.049,3.049,0,0,1,1.859,1.692,3.151,3.151,0,0,1-.085,2.694l-.28.551.5.5.717-.366a3.058,3.058,0,0,1,2.515-.116,3.145,3.145,0,0,1,1.845,1.961Zm.217-4.5a3.75,3.75,0,1,1,3.75-3.75A3.754,3.754,0,0,1,169,133.75Zm0-6a2.25,2.25,0,1,0,2.25,2.25A2.252,2.252,0,0,0,169,127.75Z"
            transform="translate(-159.25 -120.25)"
            :class="fillClass + ' transition-fill'"
        />
    </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "MenuLeftIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
    },
});
</script>
