export const ENV = process.env.NODE_ENV == "production" ?
    {
        API_URL: "https://config-api.gym80.de/api",
        IMAGE_URL: "https://config-api.gym80.de/storage"
    } :
    {
        //API_URL: "http://localhost:8000/api",
        //IMAGE_URL: "http://localhost:8000/api"
        API_URL: "https://config-api.gym80.de/api",
        IMAGE_URL: "https://config-api.gym80.de/storage"
    };
