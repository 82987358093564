<template>
    <svg
        id="Home"
        xmlns="http://www.w3.org/2000/svg"
        width="23.156"
        height="19.1"
        viewBox="0 0 23.156 19.1"
    >
        <path
            id="Pfad_443"
            data-name="Pfad 443"
            d="M18.3,19.1H4.9a1.538,1.538,0,0,1-1.5-1.5V6H5.2V17.2H18V6h1.8V17.5A1.561,1.561,0,0,1,18.3,19.1Z"
            transform="translate(-0.026)"
            :class="fillClass + ' transition-fill'"
        />
        <path
            id="Pfad_444"
            data-name="Pfad 444"
            d="M22.3,9.4a.764.764,0,0,1-.5-.2L11.6,1.8,1.4,9.2A.83.83,0,0,1,.2,9,.948.948,0,0,1,.4,7.7L10.6.3a1.987,1.987,0,0,1,2.1,0L22.9,7.7A1.059,1.059,0,0,1,23,9,.755.755,0,0,1,22.3,9.4Z"
            transform="translate(-0.026)"
            :class="fillClass + ' transition-fill'"
        />
    </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "HomeIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
    },
});
</script>
