import axios from "axios";
import { ENV } from "../../env";
// import store from "../store";
// import { AUTH_TYPES, STORE_TYPES } from "../store/types";
import { getData } from "./dataHelper";

const ApiService = {
    init() {
        axios.defaults.baseURL = ENV.API_URL;

        this.setHeader();
        axios.interceptors.response.use(
            (response) => response,
            (error) => this.errorHandler(error)
        );
    },

    setHeader() {
        axios.defaults.withCredentials = true;
        axios.defaults.headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        };
    },
    errorHandler(error) {
        if (getData(error, "response.status") == 401) {
            // store.dispatch(`${STORE_TYPES.AUTH}/${AUTH_TYPES.LOGOUT}`);
            console.log("NOT AUTHENTICATED");
        } else if (getData(error, "response.status") == 404) {
            console.log("NOT FOUND");
        }
        throw error;
    },
    query(resource, params) {
        return axios.get(resource, params).catch((error) => {
            return error;
        });
    },
    get(resource, slug = "") {
        return axios.get(`${resource}/${slug}`).catch((error) => {
            return error;
        });
    },
    getCookies() {
        return axios.get("https://api.Gym80.de/sanctum/csrf-cookie").catch((error) => {
            return error;
        });
    },

    async post(resource, params, config) {
        return await axios
            .post(`${resource}`, params, config)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error;
            });
    },

    update(resource, slug, params) {
        // TODO: Data to FormData
        return axios
            .put(`${resource}/${slug}`, params)
            .then((response) => {
                return response;
            })
            .catch(() => {
                return;
            });
    },

    delete(resource) {
        return axios.delete(resource).catch((error) => {
            return error;
        });
    },
};

export default ApiService;
