<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.004"
    height="24.004"
    viewBox="0 0 24.004 24.004"
    class="group"
  >
    <path
      id="instagram"
      d="M16,12h0a4,4,0,1,0-1.172,2.829A3.843,3.843,0,0,0,16,12.06c0-.021,0-.042,0-.063v0Zm2.16,0a6.135,6.135,0,1,1-1.8-4.359,5.922,5.922,0,0,1,1.8,4.256c0,.036,0,.073,0,.109V12Zm1.687-6.406v0a1.441,1.441,0,1,1-.422-1.018,1.38,1.38,0,0,1,.415.988v.029h0Zm-7.84-3.44-1.195-.008q-1.086-.008-1.649,0t-1.508.047a12.585,12.585,0,0,0-1.683.17l.073-.01a6.254,6.254,0,0,0-1.163.3l.043-.014A4.117,4.117,0,0,0,2.653,4.9l-.01.027a5.975,5.975,0,0,0-.284,1.083l-.005.037A11.759,11.759,0,0,0,2.2,7.632v.021q-.039.946-.047,1.508t0,1.649Q2.155,11.9,2.155,12T2.147,13.2q-.008,1.087,0,1.649t.047,1.508a12.585,12.585,0,0,0,.17,1.683l-.01-.073a6.254,6.254,0,0,0,.3,1.163l-.014-.043A4.117,4.117,0,0,0,4.9,21.359l.027.01a5.976,5.976,0,0,0,1.083.284l.037,0a11.76,11.76,0,0,0,1.588.159h.021q.946.039,1.508.047t1.649,0L12,21.841l1.195.008q1.086.008,1.649,0T16.35,21.8a12.585,12.585,0,0,0,1.683-.17l-.073.01a6.254,6.254,0,0,0,1.163-.3l-.043.014A4.117,4.117,0,0,0,21.352,19.1l.01-.027a5.976,5.976,0,0,0,.284-1.083l0-.037a11.76,11.76,0,0,0,.159-1.588v-.021q.039-.946.047-1.508t0-1.649q-.008-1.087-.008-1.195t.008-1.195q.008-1.087,0-1.649t-.047-1.508a12.585,12.585,0,0,0-.17-1.683l.01.073a6.254,6.254,0,0,0-.3-1.163l.014.043a4.117,4.117,0,0,0-2.254-2.272l-.027-.01A5.975,5.975,0,0,0,18,2.343l-.037-.005a11.76,11.76,0,0,0-1.588-.159h-.021q-.946-.039-1.508-.047t-1.649,0ZM24,12q0,3.578-.08,4.953c0,.1.009.219.009.337a6.64,6.64,0,0,1-6.64,6.64q-.178,0-.354-.009h.016Q15.576,24,12,24t-4.953-.08c-.1,0-.219.009-.337.009a6.64,6.64,0,0,1-6.64-6.64q0-.178.009-.354v.016Q0,15.578,0,12t.08-4.953c-.005-.1-.009-.219-.009-.337A6.64,6.64,0,0,1,6.707.07q.178,0,.354.009H7.045Q8.42,0,12,0t4.953.08c.1-.005.219-.009.337-.009a6.64,6.64,0,0,1,6.64,6.64q0,.178-.009.354V7.047Q24,8.421,24,12Z"
      transform="translate(0.004 0.002)"
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ArrowIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>
