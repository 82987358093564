<template>
  <svg
    id="open_with_black_24dp"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path id="Pfad_477" data-name="Pfad 477" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Pfad_478"
      data-name="Pfad 478"
      d="M10,9h4V6h3L12,1,7,6h3ZM9,10H6V7L1,12l5,5V14H9Zm14,2L18,7v3H15v4h3v3Zm-9,3H10v3H7l5,5,5-5H14Z"
      :class="fillClass + ' transition-fill-opacity'"
    />
  </svg>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ArrowIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>
